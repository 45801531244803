export default {
  state: {
    // safetyIncidentWSConnected: false,
    wsData: []
  },
  getters: {
    // safetyIncidentWSConnected: state => state.safetyIncidentWSConnected,
    wsData: state => state.wsData
  },
  mutations: {
    // SET_SAFETY_INCIDENT_WS_CONNECTED: (state, data) => {
    //   state.connected = data
    // },
    SET_WS_DATA(state, data) {
      const index = state.wsData.findIndex(item => item.monitorType === data.monitorType)
      if (index > -1) {
        // 使用数组的 splice 方法替换原有数据
        state.wsData.splice(index, 1, data)
      } else {
        state.wsData.push(data)
      }
    }
  },
}