import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import ws from './modules/ws'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        common,
        ws
        
    }
})