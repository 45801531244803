import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import store from './store/'
import ElementUI from 'element-ui';
import https from '@/common/https'
import utils from '@/common/utils'
import i18n from './i18n/index'
import WebLayout from '@/components/WebLayout'
import PaginationBox from '@/components/PaginationBox'
import PubSub from "pubsub-js";
import { setLanguageChangeCallback } from "./common/LanguageSwitchManager"
import './assets/iconfont.css'
import { alarm } from '@/common/audio'
import BaseSocket from "@/common/BaseSocket";

Vue.use(ElementUI);

// alert 弹窗配置，同一内容只能弹出一次
let alertMessages = {};
Vue.prototype.$alert = (message, title, options = {}) => {
  // 创建一个唯一的消息标识符  
  const msgKey = `${message}`;

  // 如果相同内容已弹出，则返回  
  if (alertMessages[msgKey]) {
    return
  }

  // 标记已展示该消息  
  alertMessages[msgKey] = true;
  const cb = options.callback;
  delete options.callback;
  return ElementUI.MessageBox.alert(message, title, {
    ...options,
  })
    .then(() => {
      if (typeof cb === 'function') {
        cb()
      }
      return Promise.resolve();
    })
    .catch(() => {
      return Promise.reject();
    })
    .finally(() => {
      // 弹窗关闭后清除对应的消息，以允许再次弹窗
      delete alertMessages[msgKey];
    });
};
Vue.prototype.$alarm = alarm
// ws 连接，判断immediate为真，为真立即创建连接，否则等待userinfoCompleted完成，判断SAFETY是否为true，是就创建连接
Vue.prototype.$ws = (immediate) => {
  return new Promise((resolve) => {
    if (immediate) {
      resolve(BaseSocket.getInstance('/ws/inspection/monitor'))
    } else {
      PubSub.subscribe('userinfoCompleted', () => {
        if (store.getters.menuFuntionAuth('SAFETY')) {
          resolve(BaseSocket.getInstance('/ws/inspection/monitor'))
        } else {
          resolve()
        }
      })
    }
  })
}


Vue.component('WebLayout', WebLayout);
Vue.component('PaginationBox', PaginationBox);

Vue.config.productionTip = false
Vue.prototype.$http = https
Vue.prototype.$utils = utils
Vue.prototype.$setLanguageChangeCallback = setLanguageChangeCallback
Vue.prototype.$pubsub = PubSub

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
